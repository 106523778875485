/**
 * @typedef {Object} Obj
 * @property {string} [type]
 * @property {string} [Type]
 * @property {*} [payload]
 * @property {*} [Payload]
 * @property {string} [requestId]
 * @property {string} [RequestId]
 */

function uuid(a) {
  return a
    // eslint-disable-next-line no-bitwise
    ? (a ^ ((Math.random() * 16) >> (a / 4))).toString(16)
    : ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, uuid);
}

class Message {
  /**
   * @static
   * @type {string}
   */
  static CAMEL_CASE = 'camelCase';

  /**
   * @static
   * @type {string}
   */
  static PASCAL_CASE = 'PascalCase';

  /**
   *
   * @param {string} type - Type of the message
   * @param {Object} payload - Payload of the message
   * @param {string} [id] - Id of the message
   */
  constructor(type, payload, id) {
    /** @type {string} */
    this.type = type;

    /** @type {Object} */
    this.payload = payload;

    /** @type {string} */
    this.id = id === null ? uuid() : id;
  }

  /**
   * @static
   * @param {Obj} obj
   * @returns {Message}
   */
  static fromObject(obj) {
    return new Message(obj.type || obj.Type, obj.payload || obj.Payload, obj.requestId || obj.RequestId);
  }

  /**
   * @param {CallableFunction} resolve
   * @param {CallableFunction} reject
   */
  setHandlers(resolve, reject) {
    this.resolver = resolve;
    this.reject = reject;
  }

  /**
   * @param {Message.CAMEL_CASE|Message.PASCAL_CASE} format
   * @returns {string}
   */
  toJSONString(format = 'camelCase') {
    return JSON.stringify({
      [format === Message.PASCAL_CASE ? 'Type' : 'type']: this.type,
      [format === Message.PASCAL_CASE ? 'Payload' : 'payload']: this.payload,
      [format === Message.PASCAL_CASE ? 'RequestId' : 'requestId']: this.id,
    });
  }
}

/**
 * WebSocketClient's Request object
 */
class Request extends Message {}
/**
 * WebSocketClient's Push object
 */
class Push extends Message {}

export default Message;
export { Message, Request, Push };
