import { Message, Request, Push } from './Message.js';
import applyMiddlewares from './middlewares.js';
import { messageFactory } from './utils.js';

class WebSocketClient {
  /**
   * @typedef {'OPENING'|'OPEN'|'CLOSED'|'CLOSED_GRACEFUL'|'CLOSED_UNAUTHORIZED'} State
   */

  /**
   * @typedef {Object} States
   * @property {State} OPENING
   * @property {State} OPEN
   * @property {State} CLOSED
   * @property {State} CLOSED_GRACEFUL
   * @property {State} CLOSED_UNAUTHORIZED
   */

  /** @type {States} */
  states = {
    OPENING: 'OPENING',
    OPEN: 'OPEN',
    CLOSED: 'CLOSED',
    CLOSED_GRACEFUL: 'CLOSED_GRACEFUL',
    CLOSED_UNAUTHORIZED: 'CLOSED_UNAUTHORIZED',
  };

  /** @private */
  intentionalCloseCodes = {
    NORMAL_CLOSURE: 1000,
    UNKNOWN_CLOSURE: 1006,
    GRACEFUL: 4000,
    UNAUTHORIZED: 4001,
    ERROR: 4002,
  };

  /**
   *
   * @param {String} url - The URL to open the WebSocket connection
   */
  constructor(url) {
    /** @type {State} */
    this.state = this.states.CLOSED;

    /** @type {String} */
    this.url = url;

    /**
     * @private
     * @type {Map}
     */
    this.requests = new Map();

    /**
     * @private
     * @type {Object<string, Array<Message>>}
     */
    this.recentMessages = {};

    this.recentMessageCount = 1;

    /**
     * @private
     * @type {Array<Object<string, CallableFunction>>}
     */
    this.onOpenResolvers = [];

    /** @type {Number} */
    this.timeout = 5000;

    /** @type {Boolean} */
    this.autoReconnect = true;

    /**
     * @private
     * @type {Object<string, Array<CallableFunction>>}
     */
    this.subscribers = {};

    /**
     * @private
     * @type {Object<string, Array<CallableFunction>>}
     */
    this.stateListeners = {};

    /** @type {Message.CAMEL_CASE|Message.PASCAL_CASE} */
    this.JSONFormat = Message.CAMEL_CASE;

    /** @type {Error} */
    this.timeoutError = null;
  }

  /**
   *
   * @returns {Promise}
   */
  async open() {
    this.setState(this.states.OPENING);
    this.ws = new WebSocket(this.url);
    this.ws.addEventListener('open', this.onOpen);
    this.ws.addEventListener('close', this.onClose.bind(this));
    this.ws.addEventListener('error', this.onError.bind(this));
    this.ws.addEventListener('message', this.onMessage.bind(this));
    return this.waitForOpenConnection();
  }

  /**
   *
   * @param {Array<string>} middlewares - middlewares to load
   */
  loadMiddlewares(middlewares) {
    middlewares.forEach(middleware => applyMiddlewares[middleware](this));
  }

  /**
   *
   * @param {String} type - tpe of the message
   * @param {Object} [payload] - payload of the message
   * @param {Number} [timeout] - time to wait for the response
   * @param {String} [requestId] - unique identifier of the response
   * @returns {Promise}
   */
  request(type, payload, timeout = this.timeout, requestId = null) {
    const message = new Message(type, payload, requestId);
    const promise = new Promise(async (resolve, reject) => {
      message.setHandlers(resolve, reject);
      this.requests.set(message.id, message);
      setTimeout(() => {
        this.requests.delete(message.id);
        reject(this.timeoutError || new Error(`Request '${type}' timed out`));
      }, timeout);
      try {
        await this.sendMessage(message);
      } catch (error) {
        reject(error);
      }
    });
    return promise;
  }

  /**
   *
   * @param {String} type
   * @param {Object} payload
   * @returns {Promise} Whether the send was successful or not
   */
  send(type, payload) {
    const message = new Message(type, payload);
    return this.sendMessage(message);
  }

  /**
   *
   * @private
   * @param {Message} message
   * @returns {Promise} Whether the send was successful or not
   */
  sendMessage(message) {
    try {
      this.ws.send(message.toJSONString(this.JSONFormat));
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  }

  /**
   * @param {String} type
   * @param {function} callback
   * @param {Boolean} [deliverRecent]
   * @returns {function} a function that unsubscribes the message listener
   */
  subscribe(type, callback, deliverRecent = false) {
    if (!this.subscribers[type]) {
      this.subscribers[type] = [];
    }
    this.subscribers[type].push(callback);
    if (deliverRecent && !!this.recentMessages[type]) {
      this.recentMessages[type].forEach(message => callback(message.payload));
    }
    return () => this.unsubscribe(type, callback);
  }

  /**
   * @param {String} type
   * @param {function} callback
   */
  unsubscribe(type, callback) {
    if (!this.subscribers[type]) {
      return;
    }
    this.subscribers[type] = this.subscribers[type].filter(cb => cb !== callback);
  }

  /**
   * Sets the state and notifies state listener about the change.
   * @private
   * @param {State} state
   */
  setState(state) {
    this.state = state;

    (this.stateListeners[state] || []).forEach((listener) => {
      listener();
    });
  }

  /**
   *
   * @param {*} state
   * @param {*} listener
   */
  onStateChange(state, listener) {
    if (!this.states[state]) {
      throw new Error(`${state} is not a valid WSC state`);
    }
    if (!this.stateListeners[state]) {
      this.stateListeners[state] = [];
    }

    this.stateListeners[state].push(listener);
  }

  /**
   * Closes the websocket connection
   */
  close() {
    this.ws.close(this.intentionalCloseCodes.GRACEFUL, 'WebSocketClient connection close');
  }

  /**
   * waits for 'open' connection
   */
  waitForOpenConnection() {
    if (this.state === this.states.OPEN) {
      return Promise.resolve();
    }
    return new Promise((resolve, reject) => {
      this.onOpenResolvers.push({ resolve, reject });
    });
  }

  /**
   * Returns a Promise that gets resolved with the awaited message's payload
   * @param {String} type
   * @param {Boolean} recent resolves when the message has already been received
   * @returns {Promise} payload of the message
   */
  waitForMessage(type, recent = false) {
    return new Promise((resolve) => { this.subscribe(type, resolve, recent); });
  }

  /** @private */
  onOpen = () => {
    this.setState(this.states.OPEN);
    this.settlePromisesWaitingForOpenConnection();
  };

  /** @private */
  onClose = (event) => {
    if ((this.state !== this.states.OPEN && this.state !== this.states.OPENING)
      || (event.srcElement && this.ws !== event.srcElement && event.code === this.intentionalCloseCodes.UNKNOWN_CLOSURE)) {
      return;
    }

    switch (event.code) {
      case this.intentionalCloseCodes.NORMAL_CLOSURE:
      case this.intentionalCloseCodes.GRACEFUL:
        this.setState(this.states.CLOSED_GRACEFUL);
        this.settlePromisesWaitingForOpenConnection();
        break;

      case this.intentionalCloseCodes.UNAUTHORIZED:
        this.setState(this.states.CLOSED_UNAUTHORIZED);
        this.settlePromisesWaitingForOpenConnection();
        break;

      default:
        this.setState(this.states.CLOSED);
        if (this.autoReconnect) {
          this.open();
        } else {
          this.settlePromisesWaitingForOpenConnection();
        }
        break;
    }
  }

  /** @private */
  onError = (error) => {
    if (error.message === 'WebSocket was closed before the connection was established') {
      console.warn(`${error.message} at ${error.target.url}`);
    } else {
      console.error('WebSocketClient error', error);
    }
    if (this.ws.readyState !== this.ws.OPEN) {
      this.onClose({ code: this.intentionalCloseCodes.ERROR });
    }
  }

  /**
   * @private
   * @param {Object} event
  */
  onMessage = (event) => {
    try {
      const parsed = JSON.parse(event.data);
      const message = messageFactory(parsed);
      this.broadcastMessage(message);
      if (message instanceof Request) {
        this.handleRequests(message);
      }
      if (message.type === 'close') {
        this.onClose(message.payload);
      }
      this.addToHistory(message);
    } catch (error) {
      console.error(`Failed to parse message: ${event.data}.`, error);
    }
  }

  /**
   * @private
   * @param {Message} message
   */
  broadcastMessage(message) {
    (this.subscribers[message.type] || []).forEach((callback) => {
      try {
        const ret = callback(message.payload);
        if (message instanceof Push) {
          if (ret instanceof Promise) {
            ret.then(() => this.ackPush(message));
          } else {
            this.ackPush(message);
          }
        }
      } catch (error) {
        console.error(`WebSocketClient: Error while executing subscribed callback ${message.type}.`, error);
      }
    });
  }

  /**
   *
   * @param {Message} message
   */
  addToHistory(message) {
    if (!this.recentMessages[message.type]) {
      this.recentMessages[message.type] = [];
    }
    const messageQueue = this.recentMessages[message.type];
    if (messageQueue.length === this.recentMessageCount) {
      messageQueue.splice(0, 1);
    }
    messageQueue.push(message);
  }

  /**
   * @private
   * @param {Message} message
   */
  handleRequests(message) {
    if (this.requests.has(message.id)) {
      if (message.type.toLowerCase() === 'error') {
        this.requests.get(message.id).reject(message.payload);
      }
      this.requests.get(message.id).resolver(message.payload);
    }
  }

  /**
   * Acknowledges a Push message
   * @param {Push} message
   */
  ackPush(message) {
    this.send('ack', { pushId: message.id });
  }

  /**
   * @param {any} error Object to be raised on request timeout error
   */
  setTimeoutError(error) {
    this.timeoutError = error;
  }

  settlePromisesWaitingForOpenConnection() {
    this.onOpenResolvers.forEach(({ resolve, reject }) => {
      if (this.state === this.states.OPEN) {
        resolve();
      } else {
        reject(new Error('Cannot establish connection'));
      }
    });
    this.onOpenResolvers = [];
  }
}

export default WebSocketClient;
