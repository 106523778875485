const WS_CONNECTING_ERROR = 'still in connecting state';

const applyMiddlewares = {
  Heartbeat: wsc => wsc.subscribe('Heartbeat', () => {
    wsc.send('Ack').catch((error) => {
      if (error.message.toLowerCase().includes(WS_CONNECTING_ERROR)) {
        console.warn('WebSocket is still in CONNECTING state: cannot send Ack');
      } else {
        throw error;
      }
    });
  }),
  ForcePageReload: wsc => wsc.subscribe('ForcePageReload', () => {
    window.location.reload(true);
  }),
};

export default applyMiddlewares;
