import { Message, Request, Push } from './Message.js';

/**
 * @param {String} str
 * @returns {String}
 */
function deCapitalize(str) {
  return str.charAt(0).toLowerCase() + str.slice(1);
}

/**
 * returns a new object with the keys camelCased from a PascalCase object
 * @param {Object} obj
 * @returns {Object}
 */
function toCamelCaseObject(obj) {
  const camelCaseObject = {};
  Object.entries(obj).forEach(([key, value]) => { camelCaseObject[deCapitalize(key)] = value; });
  return camelCaseObject;
}

function messageFactory(obj) {
  const message = toCamelCaseObject(obj);
  if (message.requestId) {
    return new Request(message.type, message.payload, message.requestId);
  }
  if (message.pushId) {
    return new Push(message.type, message.payload, message.pushId);
  }
  return new Message(message.type, message.payload);
}

// eslint-disable-next-line import/prefer-default-export
export { messageFactory };
